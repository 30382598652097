const groupItemBy = (array, property) => {
  const hash = {}
  const props = property.split('.');

  array.map((item, i) => {
      const key = props.reduce((acc, prop) => {
          return acc && acc[prop];
      }, array[i]);

      if (!hash[key]) hash[key] = { title: key, items: [] };

      hash[key].items.push(array[i]);
  })
  return hash;
}

export default () => {
  return {
    groupItemBy
  }
}